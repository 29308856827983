import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { normalizeName, styleEstado } from "../utils/table.uitls";
import { Paginator } from "primereact/paginator";
import { useFetchIndicator } from "../hooks/useIndicators";
import { useRootStore } from "../store/rootStore";

export const NowEntrante = () => {
  const userName = localStorage.getItem("userName");
  const { viewTypes, showView } = useRootStore((state) => state);

  const initParams = {
    rows: 5,
    page: 1,
  };

  const {
    vnHome,
    vfVerCampanasActivasEntrantes,
    vfIrDetalleAgente,
    vnListadoAgente,
    vfVerTodosLosAgentes,
  } = viewTypes;
  const showDetalle = showView(vfIrDetalleAgente, vnListadoAgente);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [lazyParams, setLazyParams] = useState(initParams);

  const buildFilterIndicator = (params) => ({
    userId: localStorage.getItem("user"),
    pageSize: params.rows,
    pageNumber: params.page,
    permitirVista: showView(vfVerTodosLosAgentes, vnListadoAgente),
    type: "ENTRANTE",
  });

  const [filterIndicator, setFilterIndicator] = useState(
    buildFilterIndicator(initParams)
  );

  useEffect(() => {
    setFilterIndicator(buildFilterIndicator(lazyParams));
  }, [lazyParams]);

  const getHorarios = (horarios) => {
    if (!horarios || horarios.length === 0) return "";

    const primerDia = horarios[0];
    const ultimoDia = horarios[horarios.length - 1];

    const from = primerDia.rangos[0]?.from || "";
    const to = ultimoDia.rangos[0]?.to || "";

    return `${primerDia.day} ${from} a ${ultimoDia.day} ${to}`;
  };

  const armarTable = (responseData) => {
    const armarParsed = (arr) => {
      return arr.map((c) => {
        return {
          _id: c._id,
          nombre: c.nombre,
          surveyId: c.idSurvey,
          voicebot: corregirNombre(c.nombreVoicebot),
          estado: c.nombreEstado,
          progreso: c.porcentajeProgreso ? c.porcentajeProgreso + " %" : "-",
          fecha: c.horarios.length > 0 ? calcularFecha(c.horarios) : "-",
          rangos:
            c.horarios.length > 0
              ? calcularRangos(c.horarios, calcularFecha(c.horarios))
              : "-",
          horario: getHorarios(c.horarios),
          tipoEncuesta: c.tipoEncuesta,
          llamadasRecibidas: c.llamadasRecibidas,
        };
      });
    };
    const _parsed = armarParsed(responseData);
    return _parsed;
  };

  const { data, isLoading, isError } = useFetchIndicator(
    filterIndicator,
    userName,
    vnHome,
    vfVerCampanasActivasEntrantes
  );

  useEffect(() => {
    if (data) {
      setCustomers(armarTable(data.indicators));
      setTotalRecords(data.pagination.count);
    }
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  /** */
  const calcularFecha = (horarios) => {
    horarios.forEach((dia) => {
      if (dia.current) {
        return dia.day;
      } else if (dia.next) {
        return dia.day;
      }
    });
    return horarios[horarios.length - 1].day;
  };

  const calcularRangos = (horarios, dia) => {
    let rangos = "";
    horarios.forEach((dias) => {
      if (dias.day == dia) {
        dias.rangos.forEach((rango) => {
          rangos += rango.from + "|";
        });
      }
    });
    rangos = rangos.slice(0, -1);
    return rangos;
  };

  const corregirNombre = (nombre) => {
    return nombre.split("_").join(" ");
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(rowData.estado) +
          " text-status"
        }
      >
        {normalizeName(rowData.estado)}
      </span>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span
          className="target-icon"
          style={{
            fontSize: "1.4rem",
            marginRight: "8px",
            color: "#2196F3",
          }}
        >
          {rowData.tipoEncuesta === "Saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="target-icon pi pi-sign-in"></i>
          )}
        </span>
        <span>{rowData.nombre}</span>
      </div>
    );
  };

  const redirectEncuesta = (data) => {
    sessionStorage.setItem("campanaId", data._id);
    sessionStorage.setItem("campanaName", data.nombre);
    sessionStorage.setItem("surveyName", data.voicebot);
    sessionStorage.setItem("campanaState", data.estado);
    sessionStorage.setItem("survey", data.surveyId);
    sessionStorage.setItem("typeSurvey", data.tipo);
    sessionStorage.setItem("campanaType", data.tipoEncuesta.toLowerCase());
    window.location.href = "/agente-detalle";
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {showDetalle && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => redirectEncuesta(rowData)}
            tooltip="Ir al detalle"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </>
    );
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  return (
    <>
      {customers && customers.length > 0 ? (
        <div className="card" style={{ margin: "1.25rem" }}>
          <div className="card-header">
            <h4>Agente Inteligente</h4>
          </div>
          <DataTable
            value={customers}
            responsiveLayout="stack"
            dataKey="id"
            stripedRows
            rows={5}
            loading={loading}
            emptyMessage="Sin resultados"
            footer={footerPage}
          >
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="left"
              field="nombre"
              header="Nombre"
              body={nameBodyTemplate}
            ></Column>
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="center"
              field="horario"
              header="Horario"
            ></Column>
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="center"
              field="estado"
              header="Estado"
              body={statusBodyTemplate}
            ></Column>
            <Column
              bodyClassName={"centerRowData"}
              alignHeader="center"
              field="llamadasRecibidas"
              header="LLamadas atendidas"
            ></Column>
            {showDetalle && (
              <Column
                bodyClassName={"centerRowData"}
                header="Ver detalle"
                alignHeader="center"
                body={actionBodyTemplate}
                exportable={false}
              ></Column>
            )}
          </DataTable>
        </div>
      ) : null}
    </>
  );
};
