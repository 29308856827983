import http from "../common/components/http-common";

export const generateReport = (
  campaignId,
  campaignName,
  userId,
  tipoReporte,
  userName,
  viewName,
  viewFunction,
  barridos?
) => {
  let data = {
    campaignId: campaignId,
    campaignName,
    reportType: tipoReporte,
    userId: userId,
    iterations: barridos ? barridos : undefined,
  };

  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.post<any>("/v2/report-campaign/", data, config);
};

export const reportStatus = (
  encuestaId,
  tipoReporte,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
    params: {
      campaignId: encuestaId,
      reportType: tipoReporte,
    },
  };

  return http.get<any>("/v2/report-campaign/", config).catch((error) => {
    throw error;
  });
};

export const downloadReport = (
  reportId: string,
  nombreEncuestra: string,
  reportType: string,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  const headers = { userName, viewName, viewFunction };

  return http
    .request({
      url: `/v2/report-campaign/${reportId}/file`,
      method: "get",
      headers: headers,
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        nombreEncuestra + "_" + reportType + ".xlsx"
      ); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
