import React from "react";
import { Avatar } from "primereact/avatar";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { LottieIcon } from "../LottieIcon";
import agent from "../../icons/ai1.json";
import campaing from "../../icons/campaign.json";
import audit from "../../icons/ai2.json";
import { useFilterStore } from "../../store/filterStore";

export const Profile = () => {
  const { currentUser } = useFilterStore((state) => state);

  return (
    <div className="tab-panel">
      <div className="profile-panel">
        <div style={{ marginRight: "1.5rem" }}>
          <Avatar
            icon="pi pi-user"
            size="xlarge"
            shape="circle"
            style={{ backgroundColor: "#f0f0f0", color: "#555" }}
          />
        </div>
        <div>
          <h3 style={{ margin: "0 0 0.5rem 0" }}>{currentUser.name}</h3>
          <p style={{ margin: 0, color: "#555" }}>{currentUser.email}</p>
        </div>
      </div>

      <div className="cards-panel">
        <div style={{ flex: 1 }}>
          <Panel header="Información Personal">
            <p>
              <strong>Nombre:</strong>
              {` ${currentUser.name}`}
            </p>
            <p>
              <strong>Email:</strong>
              {` ${currentUser.email}`}
            </p>
          </Panel>
        </div>

        <div style={{ flex: 1 }}>
          <Panel header="Datos Adicionales">
            <p>
              <strong>Teléfono:</strong>
            </p>
            <p>
              <strong>País:</strong>
            </p>
          </Panel>
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <Panel header="Mes en curso" style={{ width: "100%" }}>
          <div className="cards">
            <Card
              className="campaign"
              title="Campañas Masivas"
              style={{
                flex: 1,
                textAlign: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              <LottieIcon icon={campaing} height={60} width={60} />
              <ul>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total tiempo</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total registros</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total tiempo IA</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total voicebot activos</div>
                </li>
              </ul>
            </Card>

            <Card
              className="agent"
              title="Agente Inteligente"
              style={{
                flex: 1,
                textAlign: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              <LottieIcon icon={agent} height={60} width={60} />
              <ul>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total tiempo</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total llamados</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total agentes activos</div>
                </li>
              </ul>
            </Card>

            <Card
              className="audit"
              title="Speech Analytics"
              style={{
                flex: 1,
                textAlign: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              <LottieIcon icon={audit} height={60} width={60} />
              <ul>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total tiempo</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total audios</div>
                </li>
                <li>
                  <div className="value">0</div>
                  <div className="title">Total speechs activos</div>
                </li>
              </ul>
            </Card>
          </div>
        </Panel>
      </div>
    </div>
  );
};
