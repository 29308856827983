import React from "react";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { LottieIcon } from "../LottieIcon";
import time from "../../icons/time.json";
import audios from "../../icons/audios.json";
import audit from "../../icons/ai2.json";
import { useRootStore } from "../../store/rootStore";
import { AuditTable } from "./AuditTable";

export const Audit = () => {
  const userName = localStorage.getItem("userName");
  const { viewTypes } = useRootStore((state) => state);
  const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;

  const stats = [
    { title: "Audios", value: "0", icon: audios },
    { title: "Tiempo", value: "0", icon: time },
    { title: "Speechs activos", value: "0", icon: audit },
  ];

  return (
    <div className="tab-panel">
      <div className="dash-panel" style={{ marginTop: "0" }}>
        <div className="left-column">
          <div
            className="inner-cards"
            style={{ flexDirection: "column", margin: 0 }}
          >
            {stats.map((stat, index) => (
              <Card
                key={index}
                style={{
                  flex: 1,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div className="prop-card">
                  <LottieIcon icon={stat.icon} height={56} width={56} />
                  <div className="inner-text">
                    <div className="value audit">{stat.value}</div>
                    <div className="title">{stat.title}</div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="right-column">
          <Panel header="Consumo Detallado">
            <AuditTable />
          </Panel>
        </div>
      </div>
    </div>
  );
};
