import React, { useState, useRef, useEffect } from "react";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  reportStatus,
  generateReport,
  downloadReport,
} from "../../services/report.service";
import { RangoReport } from "./rangoReport/RangoReport";
import { reportStats, reportTypes } from "../../types/report.type";
import { ReportConfirmationDialog } from "./ReportConfirmationDialog";

export const MultiReport = ({ userName, viewName, viewFunction, campaign }) => {
  const userId = localStorage.getItem("user");
  const toast = useRef(null);
  const type = sessionStorage.getItem("campanaType");

  const {
    SUMMARY_EXCEL,
    GENERAL_EXCEL,
    INVALID_TELEPHONE,
    CAMPAIGN_SUMMARY_RANGES,
  } = reportTypes;
  const { error, notRequest, requested, building, partial, ok } = reportStats;

  const campanaId = sessionStorage.getItem("campanaId");
  const campanaName = sessionStorage.getItem("campanaName");

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [progress, setProgress] = useState(0);

  let timerPoint;

  const _reportes = [
    { tipoReporte: SUMMARY_EXCEL, label: "Resumen", estado: null },
    {
      tipoReporte: GENERAL_EXCEL,
      label: "Reporte General",
      estado: null,
    },
    {
      tipoReporte: CAMPAIGN_SUMMARY_RANGES,
      label: "Reporte por rangos",
      estado: null,
    },
  ];

  if (type === "saliente") {
    _reportes.push({
      tipoReporte: INVALID_TELEPHONE,
      label: "Reporte Telefonos Invalidos",
      estado: null,
    });
  }

  const [reportes, setReportes] = useState(_reportes);

  const sumaEstados = () => {
    const estadosSumados = new Set();
    let suma = 0;

    reportes.forEach((obj) => {
      if (
        obj.estado !== null &&
        obj.estado !== "NOT_REQUESTED" &&
        !estadosSumados.has(obj.tipoReporte)
      ) {
        suma += 1;
        estadosSumados.add(obj.tipoReporte);
      }
    });

    return suma;
  };

  useEffect(() => {
    setProgress(sumaEstados());
  }, [reportes]);

  useEffect(() => {
    estadoReporte();
  }, []);

  const estadoReporte = () => {
    const promesas = reportes.map((reporte) => {
      const { tipoReporte } = reporte;

      return reportStatus(
        campanaId,
        tipoReporte,
        userName,
        viewName,
        viewFunction
      )
        .then((response: any) => {
          try {
            let parsedData = response.data;

            if (parsedData === partial || parsedData === ok) {
              clearInterval(timerPoint);
            }
            return {
              ...reporte,
              estado: parsedData.status,
              id: parsedData.id || null,
            };
          } catch (err) {
            const error = err as { message?: string };
            return {
              ...reporte,
              estado: "error",
            };
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            // return {
            //   ...reporte,
            //   estado: notRequest,
            // };
          }
          throw error;
        });
    });

    Promise.all(promesas)
      .then((updatedReportes) => {
        setReportes(updatedReportes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generarReporte = (tipoReporte) => {
    generateReport(
      campanaId,
      campanaName,
      userId,
      tipoReporte,
      userName,
      viewName,
      viewFunction
    ).then((response: any) => {
      try {
        // console.log(response.data);
        hideReportConfirmationDialog();
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const cambiarEstado = (tipoReporte) => {
    return reportes.filter((elemento) => {
      if (elemento.tipoReporte === tipoReporte) {
        elemento.estado = "REQUESTED";
      }
      return true;
    });
  };

  const requestReport = (tipoReporte) => {
    generarReporte(tipoReporte);

    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está generando el reporte",
      life: 2000,
    });

    setReportes(cambiarEstado(tipoReporte));
    timerPoint = setInterval(() => estadoReporte(), 2000);
  };

  const descargarReport = (reportId, reportType) => {
    downloadReport(
      reportId,
      campanaName,
      reportType,
      userName,
      viewName,
      viewFunction
    );
    toast.current.show({
      severity: "success",
      summary: "Reportes",
      detail: "Se está descargando el reporte",
      life: 2000,
    });
    estadoReporte();
  };

  const [reportConfirmationDialog, setReportConfirmationDialog] =
    useState(false);
  const hideReportConfirmationDialog = () => {
    setReportConfirmationDialog(false);
  };

  const [tipoReporte, setTipoReporte] = useState(null);
  const confirmarGeneracion = (tipoReporte) => {
    setTipoReporte(tipoReporte);
    setReportConfirmationDialog(true);
  };

  const handleRequestReport = (tipoReporte) => {
    requestReport(tipoReporte);
  };

  return (
    <div className="report">
      {campaign && (
        <button
          className="label p-link"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <i className="pi pi-book"></i>
          Reportes
          {progress > 0 && <span className="indicator">{progress}</span>}
        </button>
      )}

      {isOpenMenu && (
        <ul className="lista fade-in-up">
          {reportes && reportes.length > 0 ? (
            reportes
              .filter((r) => r.tipoReporte !== "CAMPAIGN_SUMMARY_RANGES")
              .map((r) => (
                <NotificationListComponent
                  key={r.tipoReporte}
                  data={r}
                  descargarReport={descargarReport}
                  handleRequestReport={handleRequestReport}
                  confirmarGeneracion={confirmarGeneracion}
                />
              ))
          ) : (
            <div style={{ textAlign: "center", padding: "0.5rem" }}>
              No hay reportes
            </div>
          )}
          {campaign &&
            (campaign.estado === "FINALIZADA" ||
              campaign.estado === "EN_ESPERA" ||
              campaign.estado === "PAUSADA") && (
              <RangoReport
                campaign={campaign}
                userName={userName}
                viewName={viewName}
                viewFunction={viewFunction}
                userId={userId}
              />
            )}
        </ul>
      )}

      <Toast ref={toast} />

      <ReportConfirmationDialog
        reportConfirmationDialog={reportConfirmationDialog}
        hideReportConfirmationDialog={hideReportConfirmationDialog}
        generarReporte={requestReport}
        tipoReporte={tipoReporte}
      />
    </div>
  );
};

const NotificationListComponent = ({
  data,
  descargarReport,
  handleRequestReport,
  confirmarGeneracion,
}) => {
  const { error, notRequest, requested, building, partial, ok } = reportStats;
  const estado = data.estado;

  return (
    <li>
      <span className="label">{data.label}</span>

      {(estado === error || estado === notRequest) && (
        <Button
          icon={<TbReportAnalytics />}
          className="p-button p-component"
          style={{
            fontSize: "14px",
            backgroundColor: "#7986CB",
            borderColor: "#7986CB",
            padding: "2px",
            width: "25px",
          }}
          onClick={() => handleRequestReport(data.tipoReporte)}
        />
      )}

      {(estado === requested || estado === building) && (
        <Button
          icon="pi pi-spin pi-spinner"
          className="p-button p-component p-button-success"
          disabled={true}
          style={{
            fontSize: "14px",
            padding: "2px",
            width: "25px",
          }}
        />
      )}

      {(estado === partial || estado === ok) && (
        <div
          style={{
            width: "54px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            icon={<AiOutlineCloudDownload />}
            className="p-button p-component p-button p-button-success"
            style={{
              fontSize: "14px",
              padding: "2px",
              width: "25px",
            }}
            onClick={() => descargarReport(data.id, data.tipoReporte)}
            disabled={data.id === null}
          />
          <Button
            icon={<TbReportAnalytics />}
            className="p-button p-component"
            style={{
              fontSize: "14px",
              backgroundColor: "#7986CB",
              borderColor: "#7986CB",
              padding: "2px",
              width: "25px",
            }}
            onClick={() => confirmarGeneracion(data.tipoReporte)}
          />
        </div>
      )}
    </li>
  );
};
