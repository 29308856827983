import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Profile } from "./profile/Profile";
import { Audit } from "./audit/Audit";
import { Campaign } from "./campaign/Campaign";
import { Agent } from "./agent/Agent";
import { useRootStore } from "../store/rootStore";
import { useFilterStore } from "../store/filterStore";
import { useFetchClient } from "../hooks/useClient";
import { useFetchUserById } from "../hooks/useUser";

export const UserProfile = () => {
  const { setClients, currentUser, setCurrentUser, updateLazyProp } =
    useFilterStore((state) => state);

  const userName = localStorage.getItem("userName");
  const userId = localStorage.getItem("user");
  const { viewTypes } = useRootStore((state) => state);
  const { vnPerfilUsuario: viewName, vfVerPerfilUsuario: viewFunction } =
    viewTypes;

  /** */
  const _filterClient = {
    pageSize: 8000,
  };

  const fetchClient = useFetchClient(
    _filterClient,
    userName,
    viewName,
    viewFunction
  );
  useEffect(() => {
    if (fetchClient.data) {
      setClients(fetchClient.data);
    }
  }, [fetchClient.data]);
  /** */

  /** */
  const [usuarioId, setUsuarioId] = useState(userId);

  const {
    data: userData,
    isLoading: userLoading,
    error: errorUser,
    refetch: refetchUser,
  } = useFetchUserById(usuarioId, userName, viewName, viewFunction);

  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);
  /** */

  return (
    <div className="user-profile card">
      <TabView>
        <TabPanel header="Perfil">{currentUser && <Profile />}</TabPanel>
        <TabPanel header="Campañas Masivas">
          <Campaign />
        </TabPanel>
        <TabPanel header="Agente Inteligente">
          <Agent />
        </TabPanel>
        <TabPanel header="Speach Analytics">
          <Audit />
        </TabPanel>
      </TabView>
    </div>
  );
};
