export const ESTADOS = {
  PENDIENTE: 5,
  EN_ESPERA: 4,
  EN_PROCESO: 3,
  FINALIZADO: 1,
  ERROR: 2,
};

export interface TableData {
  [key: string]: any;
  estado?: number;
}

export type AudioObject = {
  estado: number;
  file?: File | { name: string };
  row?: any;
  [key: string]: any;
};

export const AudioStatus = {
  UPLOADED: "UPLOADED",
  PENDING: "PENGING",
  PROCESSING: "PROCESSING",
  PROCESSED: "PROCESSED",
  ERROR: "ERROR",
};
