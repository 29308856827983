import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { Paginator } from "primereact/paginator";
import { useFetchCampaignDuration } from "../../hooks/useDuration";
import { useFetchCampaignAmount } from "../../hooks/useAmount";
import { useRootStore } from "../../store/rootStore";
import { useFilterStore } from "../../store/filterStore";
import { useFetchCampaign } from "../../hooks/useCampaign";

export const CampaingTable = () => {
  const userName = localStorage.getItem("userName");
  const { viewTypes, showView } = useRootStore((state) => state);
  const { lazyParams, updateLazyProp } = useFilterStore((state) => state);

  const {
    vnListadoCampana,
    vfVerListadoCampana,
    vfVerDuracion,
    vfVerTodasLasCampanas,
  } = viewTypes;

  const showDuration = showView(vfVerDuracion, vnListadoCampana);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const buildFilterCampaign = (params) => ({
    userId: localStorage.getItem("user"),
    clientId: params.clientId,
    fechaInicioDesde: params.fechaInicioDesde,
    fechaInicioHasta: params.fechaInicioHasta,
    pagesize: params.rows,
    pagenumber: params.page,
    direction: params.ordenColumn,
    field: params.sortField,
    permitirVista: showView(vfVerTodasLasCampanas, vnListadoCampana),
    surveyTipo: "saliente",
  });

  const [filterCampaign, setFilterCampaign] = useState(
    buildFilterCampaign(lazyParams)
  );

  useEffect(() => {
    setFilterCampaign(buildFilterCampaign(lazyParams));
  }, [lazyParams]);

  const buildData = (responseData) => {
    const armarParsed = (arr) => {
      return arr.map((c) => {
        return {
          _id: c._id,
          nombre: corregirNombre(c.nombre),
          surveyId: c.idSurvey,
          voicebot: corregirNombre(c.surveyName),
          fecha: formatoFechaVista(c.fechaInicio),
          tipo: c.surveyTipo,
          tipoEncuesta: c.surveyTipo,
          casosARealizar: c.casosARealizar.toLocaleString(),
        };
      });
    };
    const _parsed = armarParsed(responseData);
    return _parsed;
  };

  const fetchCampaigns = useFetchCampaign(
    filterCampaign,
    userName,
    vnListadoCampana,
    vfVerListadoCampana
  );

  useEffect(() => {
    if (fetchCampaigns.data) {
      setCustomers(buildData(fetchCampaigns.data.campaigns));
      setTotalRecords(fetchCampaigns.data.pagination.count);
    }
  }, [fetchCampaigns.data]);

  useEffect(() => {
    setLoading(fetchCampaigns.isLoading);
  }, [fetchCampaigns.isLoading]);

  /** */

  const corregirNombre = (nombre) => {
    return nombre.split("_").join(" ");
  };

  const formatoFechaVista = (fecha) => {
    let date = moment(fecha);
    return date.format("DD/MM/YYYY");
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);

    updateLazyProp("page", event.page + 1);
    updateLazyProp("rows", event.rows);
  };

  const Duration = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignDuration(
      campaignId,
      userName,
      vnListadoCampana,
      vfVerDuracion
    );

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  const Amount = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignAmount(
      campaignId,
      userName,
      vnListadoCampana,
      vfVerListadoCampana
    );

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const amount = data ? data : 0;
    return <span>{amount}</span>;
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            marginRight: "16px",
            color: "#2196F3",
          }}
        >
          {rowData.tipo === "Saliente" ? (
            <i className="pi pi-sign-out"></i>
          ) : (
            <i className="target-icon pi pi-sign-in"></i>
          )}
        </span>
        <span>{rowData.nombre}</span>
      </div>
    );
  };

  const surveyBodyTemplate = (rowData) => {
    return (
      <span style={{ textAlign: "left", display: "block" }}>
        {rowData.voicebot}
      </span>
    );
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  return (
    <div className="card">
      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        rows={5}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          bodyClassName={"centerRowData"}
          alignHeader="left"
          field="voicebot"
          header="Voicebot"
          body={surveyBodyTemplate}
        ></Column>
        <Column
          bodyClassName={"centerRowData"}
          alignHeader="left"
          field="nombre"
          header="Campaña"
          body={nameBodyTemplate}
        ></Column>

        <Column
          bodyClassName={"centerRowData"}
          alignHeader="center"
          field="fecha"
          header="Fecha"
        ></Column>

        <Column
          bodyClassName={"centerRowData"}
          body={(rowData) => <Amount campaignId={rowData._id} />}
          header="Registros"
          alignHeader="center"
        />

        {showDuration && (
          <Column
            bodyClassName={"centerRowData"}
            body={(rowData) => <Duration campaignId={rowData._id} />}
            header="Tiempo"
            alignHeader="center"
          />
        )}
        <Column
          bodyClassName={"centerRowData"}
          header="Tiempo IA"
          alignHeader="center"
        />
      </DataTable>
    </div>
  );
};
