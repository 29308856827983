import { Button, Dialog } from "primereact";

export const ConfirmationDialog = ({
  action,
  detailDialog,
  setDetailDialog,
  callback,
}) => {
  const handleAccept = () => {
    if (callback) callback();
    setDetailDialog(false);
  };

  const activeUserDialogFooter = (
    <>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleAccept}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDetailDialog(false)}
      />
    </>
  );

  return (
    <Dialog
      visible={detailDialog}
      header={`${action} audios`}
      modal
      footer={activeUserDialogFooter}
      onHide={() => setDetailDialog(false)}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
          aria-hidden="true"
        />
        <span>
          {action === "Eliminar"
            ? "Se eliminarán todos los audios pendientes."
            : "Se procesarán todos los audios pendientes."}
        </span>
      </div>
    </Dialog>
  );
};
