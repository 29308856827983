import http from "../common/components/http-common";
var qs = require("qs");

class AnalyticService {
  ObtenerAnalytics(
    filters,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: filters,
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "brackets" });
      },
    };
    return http.get<any>("/v1/pbi-report", config);
  }
}

export default new AnalyticService();
