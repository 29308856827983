import React, { useEffect, useState } from "react";
import { Button, Toolbar } from "primereact";
import FileUploader from "./uploader/FileUploader";
import { AudioObject, ESTADOS } from "../types/uploaderTypes";
import auditAudioService from "../services/auditAudio.service";
import { objetoAFormData } from "../utils/objectToFormData";
import { viewTypes } from "../common/types/views.type";
import { useAuditStore } from "../store/auditStore";
import { ConfirmationProcess } from "./ConfirmationProcess";

export const AuditPanelContain = ({ closePanel, itemTemplate }) => {
  const { audit, concurrencia, updateDataProps, setListos, packageId } =
    useAuditStore((state) => state);

  const _variables = {
    obligatorias: audit.requiredFields,
    adicionales: audit.optionalFields,
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="detail-row">
        <div className="content">{audit.name}</div>
        <div>{itemTemplate(audit.active)}</div>
      </div>
    );
  };

  const [processAction, setProcessAction] = useState(null);
  const [processDialog, setProcessDialog] = useState(false);

  const hideProcessDialog = () => {
    setProcessDialog(false);
  };

  const handleClosePanel = (_action) => {
    setProcessAction(_action);
    if (updatedAudios.length > 0) {
      setProcessDialog(true);
    } else {
      setUpdatedAudios([]);
      closePanel();
    }
  };

  const handleProcesar = () => {
    const _id = audit.id;
    auditAudioService
      .procesarAudios(_id, packageId, userName, viewName, viewFunction)
      .catch((e) => {
        console.error(e);
        throw e;
      });

    hideProcessDialog();
    setUpdatedAudios([]);
    closePanel();
  };

  const handleBorrar = () => {
    const _id = audit.id;
    auditAudioService
      .borrarAudios(_id, packageId, userName, viewName, viewFunction)
      .catch((e) => {
        console.error(e);
        throw e;
      });

    hideProcessDialog();
    setUpdatedAudios([]);
    closePanel();
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Procesar"
            className="p-button-success"
            onClick={() => handleClosePanel("procesar")}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => handleClosePanel("borrar")}
          />
        </div>
      </>
    );
  };

  const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;
  const userId = localStorage.getItem("user");
  const userName = localStorage.getItem("userName");

  const [updatedAudios, setUpdatedAudios] = useState<AudioObject[]>([]);

  useEffect(() => {
    const currentlyInProcess = updatedAudios.filter(
      (audio) => audio.estado === ESTADOS.EN_PROCESO
    );

    let remainingCapacity = concurrencia - currentlyInProcess.length;

    if (remainingCapacity > 0) {
      let audiosConcurrencia = updatedAudios.map((audio) => {
        if (audio.estado === ESTADOS.EN_ESPERA && remainingCapacity > 0) {
          remainingCapacity--;

          const updatedAudio = { ...audio, estado: ESTADOS.EN_PROCESO };
          updateDataProps(updatedAudio.file.name, [
            { prop: "estado", value: ESTADOS.EN_PROCESO },
          ]);
          saveAuditAudio(updatedAudio);

          return updatedAudio;
        }
        return audio;
      });

      if (
        JSON.stringify(updatedAudios) !== JSON.stringify(audiosConcurrencia)
      ) {
        setUpdatedAudios(audiosConcurrencia);
      }
    }
  }, [concurrencia, updatedAudios]);

  const saveAuditAudio = (audio: AudioObject) => {
    if (audio.row) {
      const { id, estado, error, ...jsonFieldValues } = audio.row;

      const auditAudio = {
        userId: userId,
        auditId: audit.id,
        mimetype: "audio/mpeg",
        fileName: audio.file?.name ?? "",
        file: audio.file,
        jsonFieldValues: JSON.stringify(jsonFieldValues),
        packageId: packageId,
      };

      setUpdatedAudios((prevAudios) =>
        prevAudios.map((aud) =>
          aud.file?.name === auditAudio.fileName
            ? {
                ...aud,
                file: { name: aud.file.name },
              }
            : aud
        )
      );

      auditAudioService
        .CrearAuditAudio(
          objetoAFormData(auditAudio),
          userName,
          viewName,
          viewFunction
        )
        .then((response) => {
          if (response.status === 200) {
            setListos((prevListos) => prevListos + 1);
            updateDataProps(audio.file?.name ?? "", [
              { prop: "id", value: response.data.id },
            ]);
            setUpdatedAudios((prevAudios) =>
              prevAudios.map((aud) =>
                aud.file?.name === auditAudio.fileName
                  ? { ...aud, estado: ESTADOS.FINALIZADO }
                  : aud
              )
            );
            updateDataProps(auditAudio.fileName, [
              { prop: "estado", value: ESTADOS.FINALIZADO },
            ]);
          }
        })
        .catch((err) => {
          const errorMessage =
            err.response?.data?.message || err.message || "Error desconocido";
          setUpdatedAudios((prevAudios) =>
            prevAudios.map((aud) =>
              aud.file?.name === auditAudio.fileName
                ? { ...aud, estado: ESTADOS.ERROR }
                : aud
            )
          );
          updateDataProps(auditAudio.fileName, [
            { prop: "estado", value: ESTADOS.ERROR },
            { prop: "error", value: errorMessage },
          ]);
        });
    }
  };

  const uploadAudio = (
    existingAudios: AudioObject[],
    newAudios: AudioObject[]
  ) => {
    setUpdatedAudios((prevAudios) => {
      const allAudios = [...prevAudios, ...existingAudios, ...newAudios].filter(
        (audio, index, self) =>
          index === self.findIndex((a) => a.file.name === audio.file.name)
      );

      const updatedAudios = allAudios.map((audio) => {
        const prevAudio = prevAudios.find(
          (a) => a.file.name === audio.file.name
        );

        // audio existe y ERROR reinicia el proceso
        if (prevAudio && prevAudio.estado === ESTADOS.ERROR) {
          updateDataProps(audio.file.name, [
            { prop: "estado", value: ESTADOS.EN_ESPERA },
          ]);
          return { ...audio, estado: ESTADOS.EN_ESPERA };
        }

        if (prevAudio) {
          return { ...audio, estado: prevAudio.estado };
        }

        // audio nuevo EN_ESPERA
        updateDataProps(audio.file.name, [
          { prop: "estado", value: ESTADOS.EN_ESPERA },
        ]);
        return { ...audio, estado: ESTADOS.EN_ESPERA };
      });

      return updatedAudios;
    });
  };

  const formTemplate = (
    <FileUploader uploadAudio={uploadAudio} _variables={_variables} />
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      {formTemplate}

      <ConfirmationProcess
        processDialog={processDialog}
        hideProcessDialog={hideProcessDialog}
        handleBorrar={handleBorrar}
        handleProcesar={handleProcesar}
        processAction={processAction}
        updatedAudios={updatedAudios}
      />
    </div>
  );
};
