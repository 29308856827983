import React, { useState, useEffect, useRef } from "react";
import { InputText, Button } from "primereact";
import { Toast } from "primereact/toast";
import { genId } from "../../utils/idGenerator";
import { useAuditStore } from "../../store/auditStore";

const AditionalsComponent = ({ required = false, returnAditionalValue }) => {
  const { audit, errors } = useAuditStore((state) => state);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const toast = useRef(null);

  const _prop = required ? "requiredFields" : "optionalFields";

  useEffect(() => {
    if (Array.isArray(audit[_prop])) {
      const initialTags = audit[_prop].map((label) => ({
        label,
        id: genId(),
      }));
      setTags(initialTags);
    } else {
      setTags([]);
    }
  }, [audit, _prop]);

  const handleAddTag = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !tags.some((tag) => tag.label === trimmedValue)) {
      const newTag = {
        label: trimmedValue,
        id: genId(),
      };

      const updatedTags = [...tags, { ...newTag }];

      setTags(updatedTags);
      returnAditionalValue(
        _prop,
        updatedTags.map((tag) => tag.label)
      );
    }
    setInputValue("");
  };

  const handleDeleteTag = (idToDelete) => {
    const updatedTags = tags.filter((tag) => tag.id !== idToDelete);
    setTags(updatedTags);

    returnAditionalValue(
      _prop,
      updatedTags.map((tag) => tag.label)
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9-_ ]*$/.test(value)) {
      setInputValue(value);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        className="tag-editor"
        style={{
          border: "1px solid",
          borderColor:
            required && errors.requiredFields ? "#f44336" : "#ced4da",
        }}
      >
        <div className="p-inputgroup">
          <InputText
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Agregar un texto"
          />
          <Button
            label="Agregar"
            onClick={handleAddTag}
            disabled={!inputValue.trim()}
          />
        </div>
        <div className="tag-list">
          {tags.map((tag) =>
            tag.status === "deleted" ? null : (
              <div
                key={tag.id}
                className={`custom-tag ${
                  tag.status === "new" ? "new-tag" : ""
                }`}
              >
                {tag.label}
                <button
                  onClick={() => handleDeleteTag(tag.id)}
                  style={{
                    border: 0,
                    background: "transparent",
                    margin: 0,
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <i className="pi pi-times tag-remove-icon"></i>
                </button>
              </div>
            )
          )}
        </div>
      </div>
      {required && errors.requiredFields && (
        <small className="p-error block">{errors.requiredFields}</small>
      )}
    </>
  );
};

export default AditionalsComponent;
