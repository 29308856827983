import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { useRootStore } from "../../store/rootStore";
import { useFilterStore } from "../../store/filterStore";
import { useFetchAudit } from "../../hooks/useAudit";

export const AuditTable = () => {
  const userName = localStorage.getItem("userName");
  const { viewTypes } = useRootStore((state) => state);
  const { lazyParams, updateLazyProp } = useFilterStore((state) => state);

  const { vnAuditorias: viewName, vfVerAuditorias: viewFunction } = viewTypes;

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);

  const buildFilterAudit = (params) => ({
    ...params,
    size: params.rows,
  });

  const fetchAudit = useFetchAudit(
    buildFilterAudit(lazyParams),
    userName,
    viewName,
    viewFunction
  );

  useEffect(() => {
    if (fetchAudit.data) {
      setTotalRecords(fetchAudit.data.pagination.count);
      setCustomers(fetchAudit.data.audits);
    }
    if (fetchAudit.isLoading !== loading) {
      setLoading(fetchAudit.isLoading);
    }
  }, [fetchAudit.data, fetchAudit.isLoading]);

  const pageChange = (event) => {
    updateLazyProp("page", event.page + 1);
    updateLazyProp("rows", event.rows);
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={(lazyParams.page - 1) * lazyParams.rows}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  return (
    <DataTable
      value={customers}
      responsiveLayout="stack"
      dataKey="id"
      stripedRows
      loading={loading}
      emptyMessage="Sin resultados"
      footer={footerPage}
    >
      <Column
        field="name"
        showFilterMenu={false}
        header="Nombre"
        alignHeader="left"
      />
      <Column
        bodyClassName={"centerRowData"}
        alignHeader="center"
        field="clientName"
        header="Cliente"
      ></Column>
    </DataTable>
  );
};
