import http from "../common/components/http-common";

export const generateReport = (
  userId,
  clientId,
  startDate,
  endDate,
  tipoReporte,
  campaignType,
  userName,
  viewName,
  viewFunction
) => {
  let data = {
    reportType: tipoReporte,
    campaignType: campaignType,
    userId: userId,
    clientId: clientId,
    startDate: startDate,
    endDate: endDate,
  };

  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.post<any>("/v2/report-client/", data, config);
};

export const reportStatus = (
  userId,
  tipoReporte,
  campaignType,
  userName,
  viewName,
  viewFunction
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
    params: {
      userId: userId,
      reportType: tipoReporte,
      campaignType: campaignType,
    },
  };

  return http.get<any>("/v2/report-client/", config).catch((error) => {
    throw error;
  });
};

export const downloadReport = (
  reportId: string,
  reportType: string,
  campaignType: string,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  const headers = { userName, viewName, viewFunction };
  const reportName =
    campaignType === "SALIENTE"
      ? "Uso Campañas Masivas"
      : "Uso Agente Inteligente";

  return http
    .request({
      url: `/v2/report-client/${reportId}/file`,
      method: "get",
      headers: headers,
      responseType: "blob", //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", reportName + ".xlsx"); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
