import React, { useEffect } from "react";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { LottieIcon } from "../LottieIcon";
import voicebot from "../../icons/voicebot.json";
import time from "../../icons/time.json";
import iatime from "../../icons/iatime.json";
import records from "../../icons/records.json";
import { CampaingTable } from "./CampaingTable";
import { useRootStore } from "../../store/rootStore";
import { useFilterStore } from "../../store/filterStore";
import FilterSection from "../filterSection/FilterSection";

export const Campaign = () => {
  const stats = [
    { title: "Registros", value: "0", icon: records },
    { title: "Tiempo", value: "0", icon: time },
    { title: "Tempo IA", value: "0", icon: iatime },
    { title: "Voicebots activos", value: "0", icon: voicebot },
  ];

  const userName = localStorage.getItem("userName");
  const { viewTypes } = useRootStore((state) => state);
  const { vnListadoCampana: viewName, vfVerListadoCampana: viewFunction } =
    viewTypes;

  const { cleanLazyParams } = useFilterStore((state) => state);

  useEffect(() => {
    cleanLazyParams();
  }, []);

  return (
    <div className="tab-panel">
      <FilterSection
        type="campaign"
        userName={userName}
        viewName={viewName}
        viewFunction={viewFunction}
      />
      <div className="dash-panel">
        <div className="left-column">
          <div
            className="inner-cards"
            style={{ flexDirection: "column", margin: 0 }}
          >
            {stats.map((stat, index) => (
              <Card
                key={index}
                style={{
                  flex: 1,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div className="prop-card">
                  <LottieIcon icon={stat.icon} height={56} width={56} />
                  <div className="inner-text">
                    <div className="value campaign">{stat.value}</div>
                    <div className="title">{stat.title}</div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="right-column">
          <Panel header="Consumo Detallado">
            <CampaingTable />
          </Panel>
        </div>
      </div>
    </div>
  );
};
