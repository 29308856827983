import React, { useEffect } from "react";
import DateRangeCalendar from "./DateRangeCalendar";
import ClientSelector from "./ClientSelector";
import { CompReport } from "../CompReport";

const FilterSection = ({ type, userName, viewName, viewFunction }) => {
  return (
    <div className="filter-section">
      <ClientSelector />
      <DateRangeCalendar />

      <CompReport
        type={type}
        userName={userName}
        viewName={viewName}
        viewFunction={viewFunction}
      />
    </div>
  );
};

export default FilterSection;
