import { useQuery } from "@tanstack/react-query";
import clientService from "../services/client.service";

// Fetch client
const fetchClient = async (filter, userName, viewName, viewFunction) => {
  const response = await clientService.ObtenerClientes(
    filter,
    userName,
    viewName,
    viewFunction
  );

  return response.data.clientes;
};

export function useFetchClient(filter, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["clients"],
    queryFn: () => fetchClient(filter, userName, viewName, viewFunction),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
