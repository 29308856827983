import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import ListDataService from "../services/list.service";
import { ISurveyFilter } from "../types/survey.type";
import Container from "react-bootstrap/Container";
import { styleEstadoLlamado } from "../utils/table.uitls";
import { Paginator } from "primereact/paginator";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { CompReport } from "./reports/CompReport";
import { FilterPanel } from "./FilterPanel";
import moment from "moment";
import { useFetchSurveyDuration } from "../hooks/useDuration";

interface params {
  rows: number;
  page: number;
  sortField: any;
  ordenColumn: any;
  destinationNumber: string;
  nombre: string;
  documento: string;
  startDate: string;
  horaInicio: string;
  estadoFinal: string;
  asterisk: string;
}

export default function SurveyTable({ showDescargar, estado, viewId }) {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [viewNombre, setViewNombre] = useState(false);
  const [viewDNI, setViewDNI] = useState(false);
  const [keyNombre, setKeyNombre] = useState(null);
  const [keyDNI, setKeyDNI] = useState(null);
  const [viewAsterisk, setViewAsterisk] = useState(false);
  const [customFirst, setCustomFirst] = useState(0);
  const [statusSelect, setStatusSelect] = useState<any>(null);

  const initParams: params = {
    rows: 20,
    page: 1,
    sortField: null,
    ordenColumn: null,
    destinationNumber: "",
    nombre: "",
    documento: "",
    startDate: "",
    horaInicio: "",
    estadoFinal: "",
    asterisk: "",
  };

  const [lazyParams, setLazyParams] = useState<params | null>(initParams);
  let loadLazyTimeout = null;

  const {
    vnDetalleAgente: viewName,
    vfVerDetalleAgente,
    vfIrDetalleLlamadoAgente,
    vfDescargarReportesDetAgente,
    vfVerColumnasAvanzadasAgente,
    vnListadoAgente,
    vfVerDuracionAgente,
  } = viewTypes;
  const userName = localStorage.getItem("userName");

  const showDetalle = ShowView(vfIrDetalleLlamadoAgente, viewName);
  const showColumnas = ShowView(vfVerColumnasAvanzadasAgente, viewName);
  const showDuration = ShowView(vfVerDuracionAgente, vnListadoAgente);

  useEffect(() => {
    obtenerListadoStatus();
  }, []);

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    let nombreKey,
      nombre = "";

    if (lazyParams.nombre != "") {
      nombre = lazyParams.nombre;
      nombreKey = keyNombre;
    }

    let dni,
      dniKey = "";

    if (lazyParams.documento != "") {
      dni = lazyParams.documento;
      dniKey = keyDNI;
    }

    let filter: ISurveyFilter = {
      destinationNumber: lazyParams.destinationNumber,
      estadoFinal: lazyParams.estadoFinal,
      startDate: lazyParams.startDate,
      horaInicio: lazyParams.horaInicio,
      nombreKey: nombreKey,
      nombreValue: nombre,
      dniKey: dniKey,
      dniValue: dni,
      pagination: {
        pageSize: lazyParams.rows,
        pageNumber: lazyParams.page,
      },
      sort: {
        direction: lazyParams.ordenColumn === 1 ? "ASC" : "DESC",
        field: lazyParams.sortField,
      },
      asterisk: lazyParams.asterisk,
    };

    loadLazyTimeout = ListDataService.obtenerSurvey(
      sessionStorage.getItem("campanaId"),
      filter,
      userName,
      viewName,
      vfVerDetalleAgente
    )
      .then((response: any) => {
        try {
          let parsedData = response.data.surveysResponse.map((c) => {
            let parsed = {
              _id: c._id,
              destinationNumber: c.destinationNumber,
              startDate: validateDate(c.fecha),
              estadoFinal: c.estadoFinal,
              nombre: obtenerNombre(c.csvData),
              documento: obtenerDocumento(c.csvData),
              asterisk: obtenerAsterisk(c.asterisk),
            };

            return parsed;
          });
          setTotalRecords(response.data.pagination.count);
          setCustomers(parsedData);

          setLoading(false);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((e: Error) => {});
  };

  const validateDate = (fecha) => {
    let date = "";
    if (fecha != null && fecha != undefined && fecha != "") {
      date = formatFechaHora(fecha);
    }
    return date;
  };

  const formatFechaHora = (fecha) => {
    let date = moment(fecha, "ddd MMM DD HH:mm:ss z YYYY");
    return date.format("DD-MM-YYYY HH:mm");
  };

  const obtenerNombre = (datos) => {
    let nombre = "";

    Object.entries(datos).forEach((element) => {
      if (
        element[0].toUpperCase().includes("NOMBRE") &&
        !element[0].toUpperCase().includes("CEDENTE")
      ) {
        setViewNombre(true);
        setKeyNombre("csvData." + element[0].toString());
        nombre = element[1].toString();
        return nombre;
      }
    });
    return nombre;
  };

  const obtenerDocumento = (datos) => {
    let dni = "";
    Object.entries(datos).forEach((element) => {
      if (
        element[0].toUpperCase().includes("DNI") ||
        element[0].toUpperCase().includes("DOCUMENTO") ||
        element[0].toUpperCase().includes("D.N.I")
      ) {
        setViewDNI(true);
        setKeyDNI("csvData." + element[0].toString());
        dni = element[1].toString();
        return dni;
      }
    });
    return dni;
  };

  const obtenerAsterisk = (datos) => {
    if (showColumnas) {
      setViewAsterisk(true);
      return datos || "";
    }
    return "";
  };

  const obtenerListadoStatus = () => {
    ListDataService.ObtenerStatus(sessionStorage.getItem("campanaId"))
      .then((response: any) => {
        try {
          let result = response.data.filter((item, index) => {
            return response.data.indexOf(item) === index;
          });
          setStatusSelect(result);
          handleOptionsSelect(result);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.startDate);
  };

  const formatDate = (value) => {
    if (value != null) {
      return value;
    }
    return "";
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        rows: event.rows,
      };
    });
  };

  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortField: event.sortField,
        ordenColumn: event.sortOrder,
      };
    });
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          rowData.estadoFinal
            ? "customer-badge status-" +
              styleEstadoLlamado(rowData.estadoFinal) +
              " text-status"
            : ""
        }
      >
        {rowData.estadoFinal}
      </span>
    );
  };

  const asteriskBodyTemplate = (rowData) => {
    if (rowData.asterisk === "") {
      return "";
    }
    return rowData.asterisk;
  };

  const [optionsSelect, setOptionsSelect] = useState([]);

  const handleOptionsSelect = (data) => {
    const op = [];

    data.forEach((estado) => {
      const obj = {
        name: estado,
        value: estado,
      };
      op.push(obj);
    });
    setOptionsSelect(op);
  };

  const redirectEncuesta = (data) => {
    sessionStorage.setItem("surveyReponseNumber", data.destinationNumber);
    sessionStorage.setItem("surveyReponseId", data._id);
    window.location.href = "/llamado";
  };

  const actionViewBodyTemplate = (rowData) => {
    return (
      <>
        {showDetalle && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => redirectEncuesta(rowData)}
            tooltip="Ir al detalle del llamado"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </>
    );
  };

  const header = () => {
    return (
      <>
        <CompReport
          userName={userName}
          viewName={viewName}
          viewFunction={vfDescargarReportesDetAgente}
        />
      </>
    );
  };

  const footerPage = (
    <div>
      <Paginator
        first={customFirst}
        rows={lazyParams.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageChange={pageChange}
      ></Paginator>
    </div>
  );

  const Duration = ({ surveyId }) => {
    const { data, error, isLoading } = useFetchSurveyDuration(surveyId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  return (
    <Container className="paddingTable">
      {ShowView(vfVerDetalleAgente, viewName) && (
        <div className="card">
          <FilterPanel
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
            initParams={initParams}
            optionsSelect={optionsSelect}
            viewNombre={viewNombre}
            viewDNI={viewDNI}
            viewAsterisk={viewAsterisk}
          />
          <DataTable
            value={customers}
            responsiveLayout="stack"
            dataKey="id"
            stripedRows
            header={showDescargar ? header : null}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.ordenColumn}
            loading={loading}
            emptyMessage="Sin resultados"
            footer={footerPage}
          >
            {viewId && (
              <Column
                bodyClassName={"centerRowData"}
                field="_id"
                sortable
                header="Id"
                alignHeader="center"
              />
            )}
            <Column
              bodyClassName={"centerRowData"}
              field="destinationNumber"
              sortable
              header="Teléfono"
              dataType="numeric"
              alignHeader="center"
            />
            {viewNombre ? (
              <Column
                bodyClassName={"centerRowData"}
                field="nombre"
                sortable
                header="Nombre"
                alignHeader="center"
              />
            ) : (
              <div style={{ display: "none" }}></div>
            )}
            {viewDNI && (
              <Column
                bodyClassName={"centerRowData"}
                field="documento"
                sortable
                header="Documento"
                alignHeader="center"
              />
            )}
            <Column
              bodyClassName={"centerRowData"}
              field="startDate"
              sortable
              header="Fecha/Hora"
              dataType="date"
              body={dateBodyTemplate}
              alignHeader="center"
            />
            <Column
              bodyClassName={"centerRowData"}
              field="estadoFinal"
              sortable
              header="Estado"
              body={statusBodyTemplate}
              alignHeader="center"
            />
            {viewAsterisk && (
              <Column
                bodyClassName={"centerRowData"}
                field="asterisk"
                sortable
                header="Asterisk"
                body={asteriskBodyTemplate}
                alignHeader="center"
              />
            )}
            {showDuration && (
              <Column
                bodyClassName={"centerRowData"}
                body={(rowData) => <Duration surveyId={rowData._id} />}
                header="Duración"
                alignHeader="center"
              />
            )}
            <Column
              bodyClassName={"centerRowData"}
              body={actionViewBodyTemplate}
              exportable={false}
            ></Column>
          </DataTable>
        </div>
      )}
    </Container>
  );
}
