export const reportStats = {
  error: "ERROR",
  notRequest: "NOT_REQUESTED",
  requested: "REQUESTED",
  building: "BUILDING",
  partial: "PARTIAL",
  ok: "OK",
};

export const reportTypes = {
  SUMMARY_EXCEL: "SUMMARY_EXCEL",
  GENERAL_EXCEL: "GENERAL_EXCEL",
  INVALID_TELEPHONE: "INVALID_TELEPHONE",
  CAMPAIGN_SUMMARY_RANGES: "CAMPAIGN_SUMMARY_RANGES",
  USAGE_REPORT: "USAGE_REPORT",
};
