import React, { useEffect, useState } from "react";
import { Button, Column, DataTable } from "primereact";
import { FilterPanel } from "./FilterPanel";
import { AudioStatus } from "../../types/uploaderTypes";

export const DetailTable = ({
  customers,
  loading,
  handleInfo,
  footerPage,
  lazyParams,
  setLazyParams,
  initParams,
}) => {
  const [jsonKeys, setJsonKeys] = useState<string[]>([]);

  useEffect(() => {
    if (customers && customers.length > 0) {
      try {
        const allKeys = new Set<string>();
        customers.forEach((customer) => {
          if (customer.jsonFieldValues) {
            const json = JSON.parse(customer.jsonFieldValues);
            Object.keys(json).forEach((key) => allKeys.add(key));
          }
        });
        setJsonKeys(Array.from(allKeys));
      } catch (error) {
        console.error("Error al procesar jsonFieldValues:", error);
      }
    }
  }, [customers]);

  const getJsonValue = (rowData, key) => {
    try {
      const jsonData = JSON.parse(rowData.jsonFieldValues);
      return jsonData[key] || "";
    } catch (error) {
      console.error("Error al obtener jsonFieldValues:", error);
      return "";
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-info-circle"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => handleInfo(rowData)}
        />
      </div>
    );
  };

  const stateTemplate = (rowData) => {
    return (
      <>
        {(rowData.status === AudioStatus.UPLOADED ||
          rowData.status === AudioStatus.PENDING ||
          rowData.status === AudioStatus.PROCESSING) && (
          <i className="pi pi-cog" style={{ color: "#0288D1" }}></i>
        )}
        {rowData.status === AudioStatus.PROCESSED && (
          <i className="pi pi-check" style={{ color: "#689F38" }}></i>
        )}
        {rowData.status === AudioStatus.ERROR && (
          <i className="pi pi-times" style={{ color: "#D32F2F" }}></i>
        )}
      </>
    );
  };

  return (
    <div>
      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="scroll"
        dataKey="id"
        stripedRows
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
        {customers && customers.length > 0 && (
          <Column
            showFilterMenu={false}
            header="ESTADO"
            alignHeader="center"
            bodyClassName={"centerRowData"}
            body={stateTemplate}
          />
        )}
        {customers && customers.length > 0 && (
          <Column
            field="subStatus"
            showFilterMenu={false}
            header="SUB_ESTADO"
            alignHeader="center"
            bodyClassName={"centerRowData"}
          />
        )}
        {jsonKeys.map((key) => (
          <Column
            key={key}
            field={key}
            header={key}
            body={(rowData) => getJsonValue(rowData, key)}
            alignHeader="center"
            bodyClassName={"centerRowData"}
          />
        ))}
      </DataTable>
    </div>
  );
};
