import { useQuery } from "@tanstack/react-query";
import auditService from "../services/audit.service";

const fetchAudit = async (filter, userName, viewName, viewFunction) => {
  const response = await auditService.obtenerAuditorias(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAudit(filter, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["audit", filter],
    queryFn: () => fetchAudit(filter, userName, viewName, viewFunction),
  });
}

// Fetch audit by id
const fetchAuditById = async (id, userName, viewName, viewFunction) => {
  const response = await auditService.getAudit(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAuditById(id, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["aud", id],
    queryFn: () => fetchAuditById(id, userName, viewName, viewFunction),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
