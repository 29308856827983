import React, { useEffect } from "react";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";
import { LottieIcon } from "../LottieIcon";
import time from "../../icons/time.json";
import calls from "../../icons/calls.json";
import agent from "../../icons/ai1.json";
import { useRootStore } from "../../store/rootStore";
import { useFilterStore } from "../../store/filterStore";
import { AgentTable } from "./AgentTable";
import FilterSection from "../filterSection/FilterSection";

export const Agent = () => {
  const stats = [
    { title: "Llamados", value: "0", icon: calls },
    { title: "Tiempo", value: "0", icon: time },
    { title: "Agentes activos", value: "0", icon: agent },
  ];

  const userName = localStorage.getItem("userName");
  const { viewTypes } = useRootStore((state) => state);
  const { vnListadoAgente: viewName, vfVerListadoAgente: viewFunction } =
    viewTypes;

  const { cleanLazyParams } = useFilterStore((state) => state);

  useEffect(() => {
    cleanLazyParams();
  }, []);

  return (
    <div className="tab-panel">
      <FilterSection
        type="audit"
        userName={userName}
        viewName={viewName}
        viewFunction={viewFunction}
      />
      <div className="dash-panel">
        <div className="left-column">
          <div
            className="inner-cards"
            style={{ flexDirection: "column", margin: 0 }}
          >
            {stats.map((stat, index) => (
              <Card
                key={index}
                style={{
                  flex: 1,
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div className="prop-card">
                  <LottieIcon icon={stat.icon} height={56} width={56} />
                  <div className="inner-text">
                    <div className="value agent">{stat.value}</div>
                    <div className="title">{stat.title}</div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>

        <div className="right-column">
          <Panel header="Consumo Detallado">
            <AgentTable />
          </Panel>
        </div>
      </div>
    </div>
  );
};
