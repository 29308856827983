import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { BarridosList } from "./BarridosList";
import {
  downloadReport,
  generateReport,
  reportStatus,
} from "../../../services/report.service";
import { reportStats, reportTypes } from "../../../types/report.type";
import { ReportConfirmationDialog } from "../ReportConfirmationDialog";

export const RangoReport = ({
  campaign,
  userName,
  viewName,
  viewFunction,
  userId,
}) => {
  const [barridosConfirmationDialog, setBarridosConfirmationDialog] =
    useState(false);
  const hideBarridosConfirmationDialog = () => {
    setBarridosConfirmationDialog(false);
  };

  const { CAMPAIGN_SUMMARY_RANGES } = reportTypes;
  const { error, notRequest, requested, building, partial, ok } = reportStats;
  let timerPoint;

  const tipoReporte = CAMPAIGN_SUMMARY_RANGES;

  useEffect(() => {
    estadoReporte();
  }, []);

  const [estado, setEstado] = useState(null);
  const [reportId, setReportId] = useState(null);

  const estadoReporte = () => {
    reportStatus(campaign.id, tipoReporte, userName, viewName, viewFunction)
      .then((response: any) => {
        try {
          let parsedData = response.data;
          if (parsedData === partial || parsedData === ok)
            clearInterval(timerPoint);

          setReportId(parsedData.id);
          setEstado(parsedData.status);
        } catch (err) {
          const error = err as { message?: string };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          // setEstado(notRequest);
        }
        throw error;
      });
  };

  const horarios = campaign && campaign.horarios;

  const _horarios = [];

  horarios.forEach((horario) => {
    horario.rangos.forEach((rango) => {
      _horarios.push({
        key: _horarios.length,
        id: horario.id,
        day: horario.day,
        date: horario.date,
        rango: rango,
      });
    });
  });

  const [barridos, setBarridos] = useState([]);
  const [selectedRangos, setSelectedRangos] = useState([]);
  const [disabledRangos, setDisabledRangos] = useState([]);

  const procesarBarrido = (data, id) => {
    const barridoRangos = data.map((obj) => {
      const { key, id: objId, rango, day } = obj;
      const { estadosALlamar, ...rangoWithoutEstados } = rango;
      return {
        ...rangoWithoutEstados,
        idDay: objId,
        key,
        day,
      };
    });
    return {
      barrido: id,
      rangos: barridoRangos,
    };
  };

  const handleBarrido = (data) => {
    const _key =
      barridos.length > 0 ? barridos[barridos.length - 1].barrido + 1 : 1;
    const _barrido = procesarBarrido(data, _key);

    setBarridos([...barridos, _barrido]);
    setDisabledRangos([...disabledRangos, ...selectedRangos]);
    setSelectedRangos([]);
  };

  const eliminarBarrido = (_key) => {
    const barridoEliminado = barridos.find(
      (barrido) => barrido.barrido === _key
    );
    if (barridoEliminado) {
      const rangosAEliminar = barridoEliminado.rangos.map((rango) => rango.key);

      const updatedDisabledRangos = disabledRangos.filter(
        (rango) => !rangosAEliminar.includes(rango.key)
      );
      setDisabledRangos(updatedDisabledRangos);
      setSelectedRangos([]);
    }

    const updatedBarridos = barridos.filter(
      (barrido) => barrido.barrido !== _key
    );
    setBarridos(updatedBarridos);
  };

  const validarRangoConBarrido = (rango) => {
    const _key = rango.key;
    if (barridos.length === 0) {
      return true;
    }

    for (const barrido of barridos) {
      for (const rango of barrido.rangos) {
        if (_key < rango.key) {
          return false;
        }
      }
    }
    return true;
  };

  const retornoDatos = (data) => {
    return data.map((item) => {
      const transformedRanges = item.rangos.map((rango) => {
        return {
          from: rango.from,
          to: rango.to,
        };
      });

      return {
        iteration: item.barrido,
        rangesIteration: {
          idDay: item.rangos[0].idDay,
          ranges: transformedRanges,
        },
      };
    });
  };

  const condicionGenerar = () => {
    if (estado === partial || estado === ok) {
      setBarridosConfirmationDialog(true);
    } else {
      generarReporte();
    }
  };

  const generarReporte = () => {
    const _barridos = retornoDatos(barridos);
    setEstado(requested);
    timerPoint = setInterval(() => estadoReporte(), 2000);
    generateReport(
      campaign.id,
      campaign.nombre,
      userId,
      CAMPAIGN_SUMMARY_RANGES,
      userName,
      viewName,
      viewFunction,
      _barridos
    ).then((response: any) => {
      try {
        // console.log(response.data);
        setBarridos([]);
        setDisabledRangos([]);
        setSelectedRangos([]);
        hideBarridosConfirmationDialog();
      } catch (err) {
        const error = err as { message?: string };
      }
    });
  };

  const descargarReport = () => {
    downloadReport(
      reportId,
      campaign.nombre,
      CAMPAIGN_SUMMARY_RANGES,
      userName,
      viewName,
      viewFunction
    );
    estadoReporte();
  };

  const [showSubMenu, setShowSubmenu] = useState(false);

  return (
    <>
      <li className="range-report">
        <Button
          className="menu-button"
          icon={showSubMenu ? "pi pi-angle-left" : "pi pi-angle-right"}
          iconPos="right"
          label="Reporte por rangos"
          onClick={() => setShowSubmenu(!showSubMenu)}
        />

        {showSubMenu && (
          <div className="range-list">
            <div className="header">Reporte por rangos</div>

            <div className="body">
              <BarridosList
                horariosRaw={horarios}
                horarios={_horarios}
                handleBarrido={handleBarrido}
                selectedRangos={selectedRangos}
                setSelectedRangos={setSelectedRangos}
                disabledRangos={disabledRangos}
                validarRangoConBarrido={validarRangoConBarrido}
              />

              <div className="result-list">
                <div className="barridos-list">
                  {barridos.map((b) => (
                    <div className="barrido" key={b.barrido}>
                      <div className="barrido-title">
                        <div>Barrido: {b.barrido}</div>
                        <Button
                          className="trash p-button-danger"
                          icon="pi pi-trash"
                          onClick={() => eliminarBarrido(b.barrido)}
                        />
                      </div>
                      <div className="barrido-list">
                        {b.rangos.map((rango) => (
                          <div className="range" key={rango.from + rango.to}>
                            {rango.day} {rango.from} - {rango.to}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="footer">
                  <Button
                    icon={<TbReportAnalytics />}
                    className="otro-icon"
                    style={{
                      paddingLeft: "8px",
                      backgroundColor: "#7986CB",
                      borderColor: "#7986CB",
                    }}
                    label="Generar reporte"
                    onClick={() => condicionGenerar()}
                    disabled={
                      barridos.length === 0 ||
                      estado === requested ||
                      estado === building
                    }
                  />

                  {(estado === requested || estado === building) && (
                    <Button
                      icon="pi pi-spin pi-spinner"
                      className="p-button p-component p-button-success"
                      disabled={true}
                      style={{
                        marginRight: ".5em",
                        marginLeft: "10px",
                        fontSize: "18px",
                        lineHeight: "1.1",
                      }}
                    />
                  )}

                  {(estado === partial || estado === ok) && (
                    <Button
                      icon={<AiOutlineCloudDownload />}
                      className="p-button p-component p-button p-button-success"
                      style={{
                        marginRight: ".5em",
                        marginLeft: "10px",
                        fontSize: "18px",
                        lineHeight: "1.1",
                      }}
                      onClick={() => descargarReport()}
                      disabled={reportId === null}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </li>

      <ReportConfirmationDialog
        reportConfirmationDialog={barridosConfirmationDialog}
        hideReportConfirmationDialog={hideBarridosConfirmationDialog}
        generarReporte={generarReporte}
        tipoReporte={tipoReporte}
      />
    </>
  );
};
