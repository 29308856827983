import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import analyticService from "../services/analytic.service";

const { vnAnalytics: viewName, vfVerAnalytics: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

// Fetch analytics
const fetchAnalytics = async (filter) => {
  const response = await analyticService.ObtenerAnalytics(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchAnalytics(filter) {
  return useQuery({
    queryKey: ["analytics", filter],
    queryFn: () => fetchAnalytics(filter),
  });
}

/** */
