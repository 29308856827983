import React, { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { useFilterStore } from "../../store/filterStore";

const ClientSelector = () => {
  const { lazyParams, updateLazyProp, clients } = useFilterStore(
    (state) => state
  );

  const clientOptions = clients.map((client) => ({
    label: client.name,
    value: client.id,
  }));

  const handleClientChange = (e) => {
    updateLazyProp("clientId", e.value);
  };

  return (
    <Dropdown
      value={lazyParams.clientId}
      options={clientOptions}
      onChange={handleClientChange}
      placeholder="Cliente"
      style={{ minWidth: "200px" }}
    />
  );
};

export default ClientSelector;
