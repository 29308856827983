import React, { useState, useEffect, useRef } from "react";
import ListDataService from "../../services/list.service";
import { OverlayPanel } from "primereact/overlaypanel";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { Panel } from "../_slidePanel/Panel";
import { CalendarPanelContain } from "../calendar/CalendarPanelContain";
import { Saliente } from "./Saliente";
import { useFetchCampaignDuration } from "../../hooks/useDuration";

export default function Indicators({ campaign }) {
  const [indicadores, setIndicadores] = useState<[any]>();
  const [horarios, setHorarios] = useState(null);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [diaActual, setDiaActual] = useState(null);
  const [colorScheme, setColorScheme] = useState("light");
  const op = useRef<OverlayPanel>(null);

  const {
    vnDetalleCampana: viewName,
    vfVerDashborad: viewFunction,
    vfVistaGraficoAvanzado,
    vfVerSeteosCampana,
    vnListadoCampana,
    vfVerDuracion,
  } = viewTypes;
  const showViewFunc = ShowView(viewFunction, viewName);
  const showGraficoAvanzado = ShowView(vfVistaGraficoAvanzado, viewName);
  const showEstadosALlamar = ShowView(vfVerSeteosCampana, viewName);
  const showDuration = ShowView(vfVerDuracion, vnListadoCampana);

  useEffect(() => {
    listarIndicator();
  }, [select]);

  const Duration = ({ campaignId }) => {
    const { data, error, isLoading } = useFetchCampaignDuration(campaignId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  const listarIndicator = () => {
    setLoading(true);
    ListDataService.obtenerIndicator(sessionStorage.getItem("campanaId"))
      .then((response: any) => {
        try {
          let parsedData = response.data.indicators[0];
          sessionStorage.setItem("surveyName", parsedData.nombreVoicebot);

          setIndicadores(parsedData);
          reordenarRangos(parsedData.horarios);
          setLoading(false);
        } catch (err) {}
      })
      .catch((e: Error) => {});
  };

  const reordenarRangos = (rangosHorarios) => {
    let diaCurrent = "";
    let dia = "";
    let rangoCurrent = "";
    let horarioPush = [];
    let diaActual = "";
    let current = false;

    rangosHorarios.forEach((dias) => {
      dia = dias.day;
      if (!dias.finalized) {
        if (dias.current) {
          diaCurrent = dia;
        } else if (dias.next) {
          diaCurrent = dia;
        }
      }
      let horario = [];
      dias.rangos.forEach((rangos) => {
        if (diaActual != dia) {
          let diaPush = {
            dia: dia,
            rangos: horario,
          };
          horarioPush.push(diaPush);
        }
        rangoCurrent = rangos["from"] + " - " + rangos["to"];

        rangos.current
          ? ((diaCurrent = rangos["from"] + " - " + rangos["to"]),
            (current = true))
          : null;
        rangos.next && !current
          ? (diaCurrent = rangos["from"] + " - " + rangos["to"])
          : null;
        let rango = {
          hora: rangoCurrent,
          estado: calcularEstado(
            rangos["current"],
            rangos["next"],
            rangos["finalized"]
          ),
        };
        horario.push(rango);
        diaActual = dia;
      });
    });

    diaCurrent = !diaCurrent ? dia + " " + rangoCurrent : diaCurrent;
    setDiaActual(diaCurrent);
    setHorarios(horarioPush);
  };

  const calcularEstado = (ahora, proximo, finalizado) => {
    let estado = "Pendiente";
    if (ahora) {
      estado = "Current";
    } else if (proximo) {
      estado = "Proximo";
    } else if (finalizado) {
      estado = "Finalizado";
    }
    return estado;
  };

  /** */
  const handleCalendar = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const [isOpen, setIsOpen] = useState(false);
  const closePanel = () => {
    setIsOpen(false);
  };
  /** */

  return (
    <>
      {horarios && horarios.length > 0 && (
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <CalendarPanelContain
            data={campaign}
            showEstadosALlamar={showEstadosALlamar}
            closePanel={closePanel}
          />
        </Panel>
      )}

      {!loading && indicadores != null && showViewFunc ? (
        <div className="capmaign-detail" data-theme={colorScheme}>
          <div className="layout-content-indicators">
            <div className="layout-dashboard">
              <Saliente
                indicadores={indicadores}
                horarios={horarios}
                diaActual={diaActual}
                handleCalendar={handleCalendar}
                campaign={campaign}
                showGraficoAvanzado={showGraficoAvanzado}
                Duration={Duration}
                showDuration={showDuration}
              />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
