import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { useFilterStore } from "../../store/filterStore";
import moment from "moment";

const DateRangeCalendar = () => {
  const { lazyParams, updateLazyProp } = useFilterStore((state) => ({
    lazyParams: state.lazyParams,
    updateLazyProp: state.updateLazyProp,
  }));

  const [dates, setDates] = useState<Date[] | null>(null);

  useEffect(() => {
    const startDate = lazyParams.fechaInicioDesde
      ? moment(lazyParams.fechaInicioDesde, "YYYY-MM-DD").toDate()
      : null;
    const endDate = lazyParams.fechaInicioHasta
      ? moment(lazyParams.fechaInicioHasta, "YYYY-MM-DD").toDate()
      : null;

    if (startDate && endDate) {
      setDates([startDate, endDate]);
    }
  }, [lazyParams.fechaInicioDesde, lazyParams.fechaInicioHasta]);

  const handleDatesChange = (value: Date[] | null) => {
    setDates(value);
    if (value && value.length === 2) {
      const [start, end] = value;

      if (start && end) {
        updateLazyProp("fechaInicioDesde", moment(start).format("YYYY-MM-DD"));
        updateLazyProp("fechaInicioHasta", moment(end).format("YYYY-MM-DD"));
      }
    }
  };

  return (
    <Calendar
      value={dates}
      onChange={(e) => handleDatesChange(e.value as Date[] | null)}
      selectionMode="range"
      readOnlyInput
      placeholder="dd/mm/aaaa - dd/mm/aaaa"
      dateFormat="dd/mm/yy"
      style={{ minWidth: "160px" }}
    />
  );
};

export default DateRangeCalendar;
