import { create } from "zustand";
import moment from "moment";

const initParams = {
  rows: 5,
  page: 1,
  sortField: null,
  ordenColumn: null,
  clientId: null,
  nombre: "",
  fechaInicioDesde: moment().startOf("month").format("YYYY-MM-DD"),
  fechaInicioHasta: moment().format("YYYY-MM-DD"),
};

export const useFilterStore = create((set) => ({
  lazyParams: initParams,

  setLazyParams: (lazyParams) => set({ lazyParams }),

  updateLazyProp: (propName, propValue) =>
    set((state) => ({
      lazyParams: {
        ...state.lazyParams,
        [propName]: propValue,
      },
    })),

  /** */
  clients: null,
  setClients: (clients) => set({ clients }),

  currentUser: null,
  initialClientId: null,

  setCurrentUser: (currentUser) =>
    set((state) => ({
      currentUser,
      initialClientId: currentUser?.client?.id || state.initialClientId,
      lazyParams: {
        ...state.lazyParams,
        clientId: currentUser?.client?.id || state.lazyParams.clientId,
      },
    })),

  cleanLazyParams: () =>
    set((state) => ({
      lazyParams: {
        ...initParams,
        clientId: state.initialClientId,
        fechaInicioDesde: moment().startOf("month").format("YYYY-MM-DD"),
        fechaInicioHasta: moment().format("YYYY-MM-DD"),
      },
    })),
}));
