import React from "react";
import { ChartDetail } from "../charts/ChartDetail";
import { Button } from "primereact/button";
import { normalizeName, styleEstado } from "../../utils/table.uitls";

export const Entrante = ({
  indicadores,
  horarios,
  diaActual,
  handleCalendar,
  campaign,
  showGraficoAvanzado,
  Duration,
  showDuration,
}) => {
  return (
    <>
      <div className="dash-container">
        <div className="column">
          <div className="demi-content">
            <div className="demi-column card grid-nogutter widget-overview-box widget-overview-box-3 card-detail">
              <span className="overview-icon">
                <i className="pi pi-tag"></i>
              </span>
              <span className="overview-title">Horarios</span>
              <div className="grid overview-detail">
                <div className="col-12">
                  <span className="mr-3">
                    {horarios && horarios.length > 0 && (
                      <div className="horariosCampaingDetail">
                        <Button
                          label={diaActual}
                          icon="pi pi-calendar"
                          iconPos="right"
                          className="p-button-outlined"
                          type="button"
                          onClick={(e) => handleCalendar()}
                          tooltip="Ver todos los rangos horarios"
                          style={{ marginTop: "12px" }}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="demi-column top card grid-nogutter widget-overview-box widget-overview-box-1 card-detail">
              <span className="overview-icon">
                <i className="pi pi-users"></i>
              </span>
              <span className="overview-title">Estado</span>
              <div className="grid overview-detail">
                <div className="col-12 flex align-items-center justify-content-center">
                  <span
                    className={
                      "status customer-badge status-" +
                      styleEstado(campaign.estado) +
                      " text-status text-size-status"
                    }
                  >
                    {normalizeName(campaign.estado)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={showDuration ? "demi-content" : ""}>
            <div
              className={
                showDuration
                  ? "demi-column card grid-nogutter widget-overview-box widget-overview-box-4 card-detail"
                  : "card grid-nogutter widget-overview-box widget-overview-box-4 card-detail"
              }
            >
              <span className="overview-icon">
                <i className="pi pi-book"></i>
              </span>
              <span className="overview-title">Llamados</span>
              <div className="grid overview-detail">
                <div className="col-12">
                  <div className="overview-number">
                    {indicadores["llamadasRecibidas"]}
                  </div>
                  <div className="overview-subtext">Total</div>
                </div>
              </div>
            </div>

            {showDuration && (
              <div className="demi-column card grid-nogutter widget-overview-box widget-overview-box-2 card-detail">
                <span className="overview-icon">
                  <i className="pi pi-clock"></i>
                </span>
                <span className="overview-title">Tiempo total agente</span>
                <div className="grid overview-detail">
                  <div className="col-12">
                    <div className="overview-number">
                      <Duration campaignId={campaign.id} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="grafico-container">
          <div className="card grid-nogutter widget-overview-box widget-overview-box-2 card-detail">
            <ChartDetail
              id={indicadores["_id"]}
              verAvanzado={showGraficoAvanzado}
            />
          </div>
        </div>
      </div>
    </>
  );
};
