import { useQuery } from "@tanstack/react-query";
import userService from "../services/user.service";

// Fetch users
const fetchUser = async (filters, userName, viewName, viewFunction) => {
  const response = await userService.ObtenerUsuarios(
    filters,
    userName,
    viewName,
    viewFunction
  );
  return response.data.users;
};

export function useFetchUser(filters, userName, viewName, viewFuction) {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUser(filters, userName, viewName, viewFuction),
    retry: false,
    refetchOnWindowFocus: false,
  });
}

// Fetch user by id
const fetchUserById = async (id, userName, viewName, viewFunction) => {
  const response = await userService.ObtenerUsuarioById(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchUserById(id, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["user", id],
    queryFn: () => fetchUserById(id, userName, viewName, viewFunction),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
