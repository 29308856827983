import React, { useEffect, useState } from "react";
import { Button, Toolbar } from "primereact";
import { Sidebar } from "primereact/sidebar";
import { DetailTable } from "./DetailTable";
import { AudioPlayer } from "./AudioPlayer";
import { useAuditStore } from "../../store/auditStore";
import { useFetchAudios } from "../../hooks/useAudios";
import { Paginator } from "primereact/paginator";
import auditAudioService from "../../services/auditAudio.service";
import { viewTypes } from "../../common/types/views.type";
import { ShowView } from "../../common/components/ShowView";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const DetailPanelContain = ({ closePanel, itemTemplate }) => {
  const {
    vnAuditorias: viewName,
    vfVerAuditorias: viewFunction,
    vfProcesarAudios,
    vfEliminarAudios,
  } = viewTypes;
  const userName = localStorage.getItem("userName");

  const showProcesar = ShowView(vfProcesarAudios, viewName);
  const showEliminar = ShowView(vfEliminarAudios, viewName);

  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { auditId, audit } = useAuditStore((state) => state);

  const [sideVisible, setSideVisible] = useState(false);
  const [audioInfo, setAudioInfo] = useState(null);
  const [audioId, setAUdioId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    size: 5,
    page: 1,
    sortBy: null,
    direction: null,
    auditId: "",
    fileName: "",
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterAudios = (params) => ({
    ...params,
    auditId: auditId,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterAudios, setFilterAudios] = useState(
    buildFilterAudios(initParams)
  );

  useEffect(() => {
    setFilterAudios(buildFilterAudios(lazyParams));
  }, [lazyParams, auditId]);

  const { data, isLoading } = useFetchAudios(filterAudios);

  useEffect(() => {
    if (data) {
      setTotalRecords(data.pagination.count);
      setCustomers(data.audios);
    }
    if (isLoading !== loading) {
      setLoading(isLoading);
    }
  }, [data, isLoading]);

  const leftToolbarTemplate = () => {
    return (
      <div className="detail-row">
        <div className="content">{audit.name}</div>
        <div>{itemTemplate(audit.active)}</div>
      </div>
    );
  };

  const [detailDialog, setDetailDialog] = useState(false);
  const [action, setAction] = useState<string>("");
  const [callBackFnc, setCallBackFnc] = useState<() => void>(() => {});

  const handleProcess = () => {
    setAction("Procesar");
    setCallBackFnc(() => processAction);
    setDetailDialog(true);
  };

  const handleDelete = () => {
    setAction("Eliminar");
    setCallBackFnc(() => deleteAction);
    setDetailDialog(true);
  };

  const processAction = () => {
    const _id = audit.id;
    setLoadingProcess(true);

    auditAudioService
      .procesarAudios(_id, null, userName, viewName, viewFunction)
      .catch((e) => {
        console.error(e);
        throw e;
      })
      .finally(() => {
        setLoadingProcess(false);
      });
  };

  const deleteAction = () => {
    const _id = audit.id;

    setLoadingDelete(true);
    auditAudioService
      .borrarAudios(_id, null, userName, viewName, viewFunction)
      .catch((e) => {
        console.error(e);
        throw e;
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          {showProcesar && (
            <Button
              icon={loadingProcess ? "pi pi-spin pi-spinner" : "pi pi-cog"}
              label="Procesar audios"
              className="p-button-success"
              onClick={() => handleProcess()}
              disabled={loadingProcess}
            />
          )}
          {showEliminar && (
            <Button
              icon={loadingDelete ? "pi pi-spin pi-spinner" : "pi pi-trash"}
              label="Borrar audios"
              className="p-button-success"
              onClick={() => handleDelete()}
              disabled={loadingDelete}
            />
          )}
          <Button
            icon="pi pi-times"
            label="Cerrar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        size: event.rows,
      };
    });
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.size}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const handleInfo = (_info) => {
    setAudioInfo(JSON.parse(_info.jsonFieldValues));
    setAUdioId(_info.id);
    setSideVisible(true);
  };

  const formTemplate = (
    <div className="body detail-panel">
      <div className="inner">
        <DetailTable
          customers={customers}
          loading={loading}
          footerPage={footerPage}
          handleInfo={handleInfo}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
        />
      </div>

      <Sidebar
        visible={sideVisible}
        position="right"
        onHide={() => setSideVisible(false)}
      >
        {audioInfo && (
          <div className="detail-info">
            <div className="id-title">Detalle del audio</div>
            <div
              style={{
                margin: "16px 0",
              }}
            >
              <AudioPlayer id={audioId} />
            </div>

            {Object.keys(audioInfo).map((prop, index) => (
              <div key={index} className="info-block">
                <div className="label-info">{prop}</div>
                <div>{audioInfo[prop]}</div>
              </div>
            ))}
          </div>
        )}
      </Sidebar>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}

      <ConfirmationDialog
        action={action}
        detailDialog={detailDialog}
        setDetailDialog={setDetailDialog}
        callback={callBackFnc}
      />
    </div>
  );
};
