import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataset,
  ChartTypeRegistry,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

type CustomChartDataset<T extends keyof ChartTypeRegistry = "bar"> =
  ChartDataset<T> & {
    porcent?: number[];
  };

export const ChartBar = ({ data, colores, vistaAvanzada }) => {
  const [dataChart, setDatachart] = useState<{
    labels: string[];
    datasets: CustomChartDataset[];
  }>({
    labels: [],
    datasets: [],
  });

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: vistaAvanzada ? "Todos los contactos" : "Llamadas atendidas",
        padding: {
          bottom: 16,
        },
        font: {
          size: 16,
          weight: "bold" as "bold",
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: "auto",
        color: "#222",
        backgroundColor: "rgba(255,255,255,.5)",
        borderRadius: 3,
        formatter: function (value, context) {
          const porcent = (context.dataset as CustomChartDataset).porcent;
          return porcent ? `${porcent[context.dataIndex]}%` : "";
        },
      },
    },
  };

  useEffect(() => {
    const formattedData = {
      labels: data.map((d) => d.estado),
      datasets: [
        {
          data: data.map((d) => d.casos),
          porcent: data.map((d) => d.porcentaje),
          backgroundColor: colores,
          borderWidth: 1,
          borderColor: "transparent",
        } as CustomChartDataset,
      ],
    };
    setDatachart(formattedData);
  }, [data, colores]);

  return <Bar data={dataChart} options={options} width={550} height={190} />;
};
